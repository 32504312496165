"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var Color = {
  black: '#000',
  white: '#FFF',
  red: '#ff0000',
  grey: '#A1A1A1',
  lightGrey: '#6d6d6d',
  green: '#6ed400'
};
var _default = Color;
exports["default"] = _default;