"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _axios = _interopRequireDefault(require("axios"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var apiUrl = process && process.env && process.env.VTO_API_URL || "https://vto-mac.elcompanies.com";
var instance = _axios["default"].create({
  baseURL: apiUrl,
  timeout: 30000,
  headers: {
    'Ocp-Apim-Subscription-Key': process && process.env && process.env.VTO_API_KEY || "ee2a4b043b514b3bbb7c7a8f562281c6"
  }
});
var _default = instance;
exports["default"] = _default;