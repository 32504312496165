import { SET_STEP } from './actionTypes';

const defaultState = {
  step: 0,
};

export const setStep = (step) => (dispatch) => {
  const action = {
    type: SET_STEP,
    payload: {
      step,
    },
  };
  dispatch(action);
};

const steps = (state = defaultState, action) => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.step,
      };
    default:
      return state;
  }
};

export default steps;
