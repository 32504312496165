"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Color = _interopRequireDefault(require("./Color"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var Footer = _styledComponents["default"].div.withConfig({
  displayName: "Footer",
  componentId: "sc-1maq6oh-0"
})(["text-align:center;font-size:0.9rem;width:100%;padding:23px 20%;box-sizing:border-box;background-color:", ";color:", ";height:100%;"], _Color["default"].black, _Color["default"].white);
var _default = Footer;
exports["default"] = _default;