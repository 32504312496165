import React, { useEffect } from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export const SERIAL_NUMBER = 'DLXC4084K822';
export const DOOR_ID = '12000117CON';
export const SAP_STORE_NUMBER = '12000117';
export const DOOR_NAME = 'MAC Queens Center CON';

export default class ApplicationInsightsManager {
    static shared = new ApplicationInsightsManager();
    static appInsights = new ApplicationInsights({ config: {
        instrumentationKey: String(process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY)
    } });

    sessionId;

    static baseParameters = {
        "App_Id": String(process.env.REACT_APP_APPLICATION_INSIGHTS_APP_ID),
        "Region_Id": String(process.env.REACT_APP_APPLICATION_INSIGHTS_REGION_ID),
        "Brand_Id": String(process.env.REACT_APP_APPLICATION_INSIGHTS_BRAND_ID),
        "Affiliate_Id": String(process.env.REACT_APP_APPLICATION_INSIGHTS_AFFILIATE_ID),
        "Door_Id": String(process.env.REACT_APP_APPLICATION_INSIGHTS_DOOR_ID),
        "App_Env": String(process.env.REACT_APP_APPLICATION_INSIGHTS_APP_ENV),
        "Analytical_Source": "Custom Printer Microsite",
        "Counter_Id": String(process.env.REACT_APP_APPLICATION_INSIGHTS_COUNTER_ID),
        "Latitude": String(process.env.REACT_APP_APPLICATION_INSIGHTS_LATITUDE),
        "Longitude": String(process.env.REACT_APP_APPLICATION_INSIGHTS_LONGITUDE),
        "SAP_Store_Number": String(process.env.REACT_APP_APPLICATION_INSIGHTS_STORE_NUMBER),
        "Country_Code": String(process.env.REACT_APP_APPLICATION_INSIGHTS_COUNTRY_CODE)
    };

    static getInstance() {
        return this.shared;
    }

    load() {
        this.sessionId = this.uuidv4();
        ApplicationInsightsManager.appInsights.loadAppInsights();
        ApplicationInsightsManager.appInsights.trackPageView();
        this.trackEvent("Initialized Application", {
            sessionId: this.sessionId,
            Serial_Number: SERIAL_NUMBER,
            SAP_Store_Number: SAP_STORE_NUMBER,
            Door_Id: DOOR_ID,
            Door_Name: DOOR_NAME
        });
        // console.log("[Applications Insights] Initalized with sessionId : ", this.sessionId);
        return;
    }

    trackEvent(event, addOnParameters) {
        const properties = Object.assign(ApplicationInsightsManager.baseParameters, addOnParameters);
        // console.log("[Applications Insights] event : ", event);
        // console.log("[Applications Insights] properties : ", properties);
        // console.log("[Applications Insights] sessionId : ", this.sessionId);
        ApplicationInsightsManager.appInsights.trackEvent({
            name: event,
            properties: properties,
            sessionId: this.sessionId
        });
    }

    uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
    }
}