import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { confirmOrder } from 'src/reducers/order';
import './Confirmation.scss';
import {
  Title,
  Button,
  EmailInput,
  Color,
  Text,
  Form,
  Label,
  ErrorMessage,
} from 'mac-uikit';
import { useTranslation } from 'react-i18next';

import ApplicationInsightsManager, {
  SERIAL_NUMBER, 
  DOOR_ID, 
  SAP_STORE_NUMBER, 
  DOOR_NAME 
} from 'src/Analytics/ApplicationInsightManager';

const ConfirmationContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100vw;
    background-color: white;
`;

const Response = styled.div`
    color: ${Color.black};
    margin: 21px 32px 0;
    display: block;
    max-width: 600px;
`;

const Wrapper = styled.div`
  margin: 48px 0 70px;
  padding: 0 17px;
  box-sizing: border-box;
  text-align: left;
  max-width: 600px;
  width: 100%;
`;

const EmailInputStyled = styled(EmailInput)`
  margin-top: 21px;
  outline: 0;
  border-width: 0 0 2px;
  border-color: ${Color.black};
  margin-top: 21px;
  width: 100%;
  padding: 0;
`;


const LabelStyled = styled(Label)`
  margin: 7px 10px 0px 25px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: auto;
  button {
    margin-bottom: 0;
  }
`;

const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TextStyled = styled(Text)`
  text-align: center;
`;

const Checkbox = styled.input`
  -webkit-appearance: none;
  max-height: 16px;
  margin-top: 3px;
  max-width: 16px;
  background-color: ${Color.white};
  border: 1px solid ${Color.black};
  padding: 7px;
  border-radius: 0px;
  display: inline-block;
  position: absolute;

  &:active, &:checked:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
  }
  
  &:checked {
    background-color: ${Color.white};
    border: 1px solid ${Color.black};
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    color: ${Color.black};
  }

  &:checked:after {
    content: "${(props) => props && '\\2713'}";
    font-size: 14px;
    position: absolute;
    top: -2px;
    left: 2px;
    color: ${Color.black};
  }
`;

const DisclaimerContainer = styled.div(props => ({
  marginTop: props.top
}));

const SignUpMessageContainer = styled.div`
  display: flex;
  margin: 2.5vh 0;
`;

const PrivacyPolicyLink = styled.a`
    color: black;
`;

const QueensCenterPrinterTitle = styled(Title)`
  line-height: 1;
`;

const Confirmation = () => {
  const history = useHistory();
  const [email, setEmail] = useState(null);
  const [optIn, setOptIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { order } = useSelector((store) => store);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    fileSentToPrinter()
  }, [])

  const sendEmail = (e) => {
    setEmail(e.target.value);
  };

  const fileSentToPrinter = () => {
    // console.log("File Sent to Printer : ", order.response.custmizeProductId);
    ApplicationInsightsManager.shared.trackEvent("ORDER_SENT_TO_PRINTER", { 
      pdfId: order.response.custmizeProductId,
      Product_sent_to_printer: order.response.productCode,
      Serial_Number: SERIAL_NUMBER,
      SAP_Store_Number: SAP_STORE_NUMBER,
      Door_Id: DOOR_ID,
      Door_Name: DOOR_NAME
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setErrorMessage(t('confirmation.enter_valid_email'));
      return;
    }
    // Send Data Point to Application Insights
    ApplicationInsightsManager.shared.trackEvent("EMAIL_SENT_TO_SELF", {
      pdfId: order.response.custmizeProductId,
      Product_sent_to_printer: order.response.productCode,
      Serial_Number: SERIAL_NUMBER,
      SAP_Store_Number: SAP_STORE_NUMBER,
      Door_Id: DOOR_ID,
      Door_Name: DOOR_NAME
    });
    dispatch(confirmOrder(email, order.response.custmizeProductId, order.type, optIn)).then(() => {
      history.push('/email');
    }).catch((error) => {
      setErrorMessage(error.message);
    });
  };

  return (
    <ConfirmationContainer>

      <Title fontSize={3} hasRightMargin={80} hasPaddingTop>{t('confirmation.sent_to')}</Title>
      <QueensCenterPrinterTitle fontSize={4.5}>{t('confirmation.queens_center_printer')}</QueensCenterPrinterTitle>
      <Response hasPaddingTop>
        <Text bold size={1.31}>{t('confirmation.confirmation')}</Text>
        <Text bold size={1.31}>{order.response.custmizeProductId}</Text>
        <Text marginTop={25} size={1}>
        {t('confirmation.product_in_print_queue')}
        </Text>
      </Response>
        <Wrapper>
          <TextStyled bold size={1.2}>{t('confirmation.want_a_copy')}</TextStyled>
          <DisclaimerContainer top={"5%"}>
          {t('confirmation.enter_email_to_get_a_copy')} <PrivacyPolicyLink href={t('privacy_policy_link')}>{t('confirmation.privacy_policy')}.</PrivacyPolicyLink>
          </DisclaimerContainer>
          <EmailInputStyled type="email" placeholder={t("confirmation.enter_your_email")} onChange={(e) => setEmail(e.target.value)} />
          { errorMessage && <ErrorMessage size={1.31} marginTop={15}>{errorMessage}</ErrorMessage>}
          <SignUpMessageContainer>
            {t('confirmation.would_you_like_to_recieve_offers')}
          </SignUpMessageContainer>
          <DisclaimerContainer>
            <Checkbox type="checkbox" checked={optIn} onChange={(e) => setOptIn(!optIn)} />
            <LabelStyled size={1.6} onClick={(e) => setOptIn(!optIn)}>
            {t('confirmation.yes_sign_me_up')} <PrivacyPolicyLink href={t('privacy_policy_link')}>{t('confirmation.privacy_policy')}</PrivacyPolicyLink>, {t('confirmation.includes_out_financial_notice')}
            </LabelStyled>
          </DisclaimerContainer>
        </Wrapper>
        <ButtonContainer>
          <Button type="submit" value={email} enabled={order.loading} inverse color={Color.black} onClick={handleSubmit}>{t('confirmation.send_email')}</Button>
        </ButtonContainer>
    </ConfirmationContainer>
  );
};

export default Confirmation;
