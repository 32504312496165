import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { initOrder } from 'src/reducers/order';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Title, Description, Color, Text,
} from 'mac-uikit';
import ExperienceSelector from 'src/components/ExperienceSelector/ExperienceSelector';
import BlackLogo from 'src/assets/images/blackLogo.png';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${Color.black};
  background-image: url(/assets/images/background.jpg);
  background-size: cover;
  background-position: center;

  ${({ variant }) => variant && `
    background-color: white;
    background-image: unset;
    h1, p {
      color: ${Color.black};
    }
  `}
 
`;

const LogoContainer = styled.div`
    margin-top: 42px;
    img {
      width: 153px;
      height: auto;
    }
`;

const TermsContainer = styled.div`
    max-width: 400px;
    padding: 0 25px;
    p { 
      margin-top: 0;
    line-height: 18px;
    text-align: center;
    }
`;

const Link = styled.a`
    color: ${Color.black};
    font-weight: bold;
`;

const StyledText = styled(Text)`
    margin-top: 15px !important;
`;

export default function () {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  // const order = useSelector(state => state.order)
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(initOrder(orderId));
  }, [dispatch, orderId]);

  return (
    <HomeContainer variant={!orderId}>
      <LogoContainer>
        <img src={BlackLogo} />
      </LogoContainer>
      <Title fontSize={5} hasPaddingTop hasRightMargin color={Color.white}>{t('experience_selector.make_it')}</Title>
      <Title fontSize={12} color={Color.white}>{t('experience_selector.yours')}</Title>
      <Description>{t('experience_selector.pick')}</Description>
      <TermsContainer>
        <Text color={Color.white} size={0.8} marginTop={25}>
          {t('experience_selector.accept_terms_and_conditions')}
          {' '}
          <Link href="/terms-conditions" target="_blank">{t('lipstick.terms_and_conditions')}</Link>
        </Text>
        <StyledText color={Color.white} size={0.8}>
          {t('experience_selector.custom_item_may_not_be_returned')}
        </StyledText>

      </TermsContainer>
      <ExperienceSelector />
    </HomeContainer>
  );
}
