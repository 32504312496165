"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var Link = _styledComponents["default"].a.withConfig({
  displayName: "Link",
  componentId: "sc-1g5ccpw-0"
})(["color:white;text-decoration:underline;margin-top:25px;"]);
var _default = Link;
exports["default"] = _default;