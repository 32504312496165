"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var HighlightText = _styledComponents["default"].p.withConfig({
  displayName: "HighlightText",
  componentId: "sc-akth2m-0"
})([""]);
var _default = HighlightText;
exports["default"] = _default;