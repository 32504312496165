import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Artifi from 'src/components/Artifi/Artifi';
import styled from 'styled-components';
import { fullfillOrder } from 'src/reducers/order';
import { useDispatch } from 'react-redux';

const EXPERIENCE_TYPE = 'Palette-X9';
const BACKEND_TYPE = 'Palettex9';

const ArtifiContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const receiveArtifiMessage = (event) => {
    const origin = event.origin || event.originalEvent.origin;
    if (Artifi && !Artifi.isValidArtifiDomain(origin)) return;

    const artifiResponse = JSON.parse(event.data);

    switch (artifiResponse.action) {
      case 'add-to-cart':
        dispatch(fullfillOrder(artifiResponse.data, BACKEND_TYPE));
        history.push('/confirm');
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    const integrationValues = {};
    integrationValues.productCode = EXPERIENCE_TYPE;
    integrationValues.websiteId = process.env.REACT_APP_ARTIFI_WEBSITE_ID;
    integrationValues.webApiclientKey = process.env.REACT_APP_ARTIFI_CLIENT_KEY;
    integrationValues.userId = '123';
    integrationValues.divId = 'artifi­container';
    integrationValues.isGuest = true;
    integrationValues.width = '100%';
    integrationValues.height = '100%';
    Artifi.Initialize(integrationValues);

    window.addEventListener('message', receiveArtifiMessage, false);
  });


  return (
    <>
      <ArtifiContainer id="artifi­container" />
    </>
  );
}
