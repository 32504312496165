import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { initOrder } from 'src/reducers/order';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Title, Description, Button, Logo, Color,
} from 'mac-uikit';
import P from 'mac-uikit/lib/ui/P';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${Color.black};
  background-image: url(/assets/images/backgroundimage.png);
  background-size: cover;
  background-position: center;

  ${({ variant }) => variant && `
    background-color: white;
    background-image: unset;
    h1, p {
      color: ${Color.black};
    }
  `}
 
`;

const DescriptionContainer = styled.div`
    margin: 0 20px;
`;

const DescriptionCopy = styled.div`
    font-size: 1.3rem;
    color: white;
    text-align: center;
    margin-top: 50px;
    white-space: pre-wrap;
`;

const LogoContainer = styled.div`
  margin-top: 36px;
`;

const ButtonContainer = styled.div`
    width: 90%;
    display: flex;
    align-items: flex-end;
    height: 100%;
`;

export default function ({ type }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [copy, setCopy] = useState({});
  // const order = useSelector(state => state.order)
  const { t } = useTranslation();
  useEffect(() => {
    // TODO: Extract this an populate a new component depending on the experience.
    if (type == 'lipstick') {
      setCopy({
        firstLine: t('home.make_it'),
        secondLine: t('home.personal'),
        body: t('home.mac_queens_center'),
        cta: t('home.lets_go'),
      });
    } else {
      setCopy({
        firstLine: t('home.get_more'),
        secondLine: t('home.personal'),
        body: t('home.mac_queens_center'),
        cta: t('home.lets_go'),
      });
    }

    dispatch(initOrder(type));
  }, [dispatch, type]);

  const startExperience = () => {
    switch (type) {
      case 'lipstick':
        history.push('/lipstick');
        break;
      case 'Palette-X4':
        history.push('/palette');
        break;
      case 'Palette-X9':
        history.push('/palette9');
        break;
      default:
        history.push('/lipstick');
    }
  };

  return (
    <HomeContainer variant={!type}>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Title fontSize={5} hasPaddingTop hasRightMargin={26} color={Color.white}>{copy.firstLine}</Title>
      <Title fontSize={7} color={Color.white}>{copy.secondLine}</Title>
      <DescriptionContainer>
        <DescriptionCopy>{copy.body}</DescriptionCopy>
      </DescriptionContainer>
      <ButtonContainer>
        <Button onClick={() => startExperience()}>{copy.cta}</Button>
      </ButtonContainer>
    </HomeContainer>
  );
}
