import api from 'vto-api';
import TagManager from 'react-gtm-module';
import {
  INIT_ORDER,
  CONFIRM_ORDER,
  START_CONFIRMATION,
  RESET_ORDER,
  FULLFILL_ORDER,
} from './actionTypes';
import i18n from 'i18next';

const defaultState = {
  id: null,
  confirmed: false,
  response: null,
  loading: false,
  type: null,
};

export const initOrder = (orderId) => (dispatch) => {
  const action = {
    type: INIT_ORDER,
    payload: {
      id: orderId,
    },
  };
  dispatch(action);
};


export const confirmOrder = (email, orderId, type, optIn) => (dispatch) => {
  dispatch({ type: START_CONFIRMATION });
  return api.post('/public/api/v2/session/sendprintingemail', {
    fileReferenceNumber: `${orderId}`,
    emailAddress: email,
    type,
    optIn,
    lng: i18n.language
  })
    .then((json) => {
      if (json.data && json.data.isSuccess) {
        const action = {
          type: CONFIRM_ORDER,
          payload: {
            confirmed: true,
            loading: false,
          },
        };
        return dispatch(action);
      }
      throw new Error('Api failed');
    }).catch((error) => {
      const action = {
        type: CONFIRM_ORDER,
        payload: {
          confirmed: false,
          loading: false,
        },
      };
      dispatch(action);
      if (error.response && error.response.data) {
        const formatError = error.response.data.errors.reduce((message, errorMessage) => `${errorMessage}, ${message}`);
        throw new Error(formatError);
      } else if (error.message) {
        throw new Error(error.message);
      } else {
        throw new Error('Internal error. Please try again.');
      }
    });
};

export const fullfillOrder = (order, type) => (dispatch) => {
  const tagManagerArgs = {
    dataLayer: {
      userId: '001',
      userProject: 'mac-red',
      page: 'confirm',
      order,
    },
  };
  TagManager.dataLayer(tagManagerArgs);

  const action = {
    type: FULLFILL_ORDER,
    payload: {
      response: order,
      type,
    },
  };

  dispatch(action);
};

export const resetOrder = () => (dispatch) => {
  dispatch({ type: RESET_ORDER });
};

const order = (state = defaultState, action) => {
  switch (action.type) {
    case INIT_ORDER:
      return {
        ...state,
        ...action.payload,
      };
    case CONFIRM_ORDER:
      return {
        ...state,
        ...action.payload,
      };
    case START_CONFIRMATION:
      return {
        ...state,
        loading: true,
      };
    case FULLFILL_ORDER:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_ORDER:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};

export default order;
