import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { resetOrder } from 'src/reducers/order';
import {
  Title, Button, Text, Color,
} from 'mac-uikit';
import { useTranslation } from 'react-i18next';

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-top: auto;
  button {
    margin-bottom: 0;
  }
`;

const Img = styled.img`
  max-height: 30vh;
  height: 100%;
  width: auto;
  margin: 50px auto;
`;
const ConfirmationContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: white;
`;

const CustomizationDescription = styled.div`
  color: ${Color.black};
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
`;

const Preview = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { order } = useSelector((store) => store);
  const componentRef = useRef();
  const { t } = useTranslation();


  const newOrder = () => {
    dispatch(resetOrder());
    history.push('/');
  };

  return (
    <ConfirmationContainer ref={componentRef}>
      <Title fontSize={4} hasPaddingTop>{t('confirmation.confirmation_sent')}</Title>
      <Img src={order.response.savedDesigns[0]} />
      <Text fontSize={1}>{t('confirmation_email.make_sure_you_are_inside_the_center')}</Text>
      <CustomizationDescription>
        <Text bold size={1.31}>{t('confirmation.confirmation')}</Text>
        <Text bold size={1.31}>{order.response.custmizeProductId}</Text>
      </CustomizationDescription>
      <ButtonContainer>
        <Button inverse color={Color.black} onClick={() => newOrder()}>{t('confirmation_email.create_new_design')}</Button>
      </ButtonContainer>
    </ConfirmationContainer>
  );
};

export default Preview;
