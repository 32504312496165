import React from 'react';
import styled from 'styled-components';

const PongContainer = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
`;

const Pong = () => <PongContainer>pong</PongContainer>;

export default Pong;
