"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var EmailInput = _styledComponents["default"].input.withConfig({
  displayName: "EmailInput",
  componentId: "sc-1diwm7y-0"
})(["border:1px solid black;width:100%;height:50px;font-size:2rem;border-radius:0;"]);
var _default = EmailInput;
exports["default"] = _default;