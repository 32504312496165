"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var Form = _styledComponents["default"].form.withConfig({
  displayName: "Form",
  componentId: "sc-lyomma-0"
})([""]);
var _default = Form;
exports["default"] = _default;