/* eslint-disable */
import jQuery from 'jquery';
import { useTranslation as t } from 'react-i18next';

let domainIp = process.env.REACT_APP_ARTIFI_URL;
const assetProtocol = window.location.protocol;
/* Fix for IE11 issue because endsWith property not present in string class thats why addding by extending it */
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (pattern) {
    const d = this.length - pattern.length;
    return d >= 0 && this.lastIndexOf(pattern) === d;
  };
}
/* This condition for build deployment using released managment */
if (domainIp.endsWith('designerurl__')) {
  domainIp = '//localhost:1534/';
}
var Artifi = Artifi || {};
Artifi.personalizationKey = 'personalization';
Artifi.IsUrlEncodingEnableInLaunching = true;


/* This condition for build deployment using released managment */
var Base64 = {
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
  encode(e) {
    let t = '';
    let n; let r; let i; let s; let o; let u; let
      a;
    let f = 0;
    e = Base64._utf8_encode(e);
    while (f < e.length) {
      n = e.charCodeAt(f++);
      r = e.charCodeAt(f++);
      i = e.charCodeAt(f++);
      s = n >> 2;
      o = (n & 3) << 4 | r >> 4;
      u = (r & 15) << 2 | i >> 6;
      a = i & 63;
      if (isNaN(r)) {
        u = a = 64;
      } else if (isNaN(i)) {
        a = 64;
      }
      t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
    }
    return t;
  },
  decode(e) {
    let t = '';
    let n; let r; let
      i;
    let s; let o; let u; let
      a;
    let f = 0;
    e = e.replace(/[^A-Za-z0-9\+\/\=]/g, '');
    while (f < e.length) {
      s = this._keyStr.indexOf(e.charAt(f++));
      o = this._keyStr.indexOf(e.charAt(f++));
      u = this._keyStr.indexOf(e.charAt(f++));
      a = this._keyStr.indexOf(e.charAt(f++));
      n = s << 2 | o >> 4;
      r = (o & 15) << 4 | u >> 2;
      i = (u & 3) << 6 | a;
      t += String.fromCharCode(n);
      if (u != 64) {
        t += String.fromCharCode(r);
      }
      if (a != 64) {
        t += String.fromCharCode(i);
      }
    }
    t = Base64._utf8_decode(t);
    return t;
  },
  _utf8_encode(e) {
    e = e.replace(/\r\n/g, '\n');
    let t = '';
    for (let n = 0; n < e.length; n++) {
      const r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
      } else if (r > 127 && r < 2048) {
        t += String.fromCharCode(r >> 6 | 192);
        t += String.fromCharCode(r & 63 | 128);
      } else {
        t += String.fromCharCode(r >> 12 | 224);
        t += String.fromCharCode(r >> 6 & 63 | 128);
        t += String.fromCharCode(r & 63 | 128);
      }
    }
    return t;
  },
  _utf8_decode(e) {
    let t = '';
    let n = 0;
    const c1 = 0;
    let c2 = 0;
    let r = 0;
    while (n < e.length) {
      r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
        n++;
      } else if (r > 191 && r < 224) {
        c2 = e.charCodeAt(n + 1);
        t += String.fromCharCode((r & 31) << 6 | c2 & 63);
        n += 2;
      } else {
        c2 = e.charCodeAt(n + 1);
        const c3 = e.charCodeAt(n + 2);
        t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
        n += 3;
      }
    }
    return t;
  },
};

// This constant used for validate data bafore add to cart.
Artifi.Constant = {
  validateBeforeSave: 'validate-data-before-save',
  continueSave: 'continue-save-design',
  addToCart: 'add-to-cart',
  orderStatus_InProgress: 'InProgress',
  orderStatus_Placed: 'Placed',
  widget_updated: 'widget-updated',
  custom_attributes_changed: 'custom-attributes-changed',
  /* Below flag is received when variant is changed inside the Artifi iframe.
     Reason behind such operation is specifically when price section is present outside the Artifi iframe .Hence, we need to sync up with client end whenever
     any variant/attribute id getting changed inside the Artifi iframe so that they(client end) can update price value promptly. */
  skuChanged: 'sku-changed',
  /* Below flag is recived when custom attribute is changed inside the Artifi iframe */
  customAttributeChanged: 'custom-attributes-changed',
  /* This flag is used to save draft name and initiate draft action when save draft call is given from outside an Artifi Iframe. */
  saveDraftExternally: 'save-draft-external',
  externalVariantData: 'external-variant-data',
  clearAllSku: 'clear-all-sku',
  insideMessageUpdate: 'inside-message-update',
  artifiInitialized: 'artifi-initialized',
  priceUpdated: 'price-updated',
  addToCartStatus: 'add-to-cart-status',
  designIsReset: 'design-is-reset',
  invalidSKU: 'invalid-sku',
};
/* Here we store Artifi  Parent container id */
Artifi.DivId = '';
Artifi.isSecurityEnable = true;

/*
 * Initialize application inside the div using iframe and integration parameters.
 * @param {Object} integrationValues  Integration values use for application initialization.
 */
Artifi.Initialize = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  Artifi.DivId = artifiIntegrationValues.divId;
  artifiIntegrationValues.appType = '';
  const src = Artifi.BuildDesignerUrl(artifiIntegrationValues);
  Artifi.loadUrlInIframe(src, artifiIntegrationValues);
};

/*
 * Initialize Preview inside the div using iframe and integration parameters.
 * @param {Object} integrationValues  Integration values use for preview initialization.
 */
Artifi.InitializePreview = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  const src = Artifi.getPreviewUrl(artifiIntegrationValues);
  Artifi.loadUrlInIframe(src, artifiIntegrationValues);
};


/*
 * Opens Preview Pop up using integration values.
 * @param {Object} integrationValues  Integration values use for preview pop up initialization.
 */
Artifi.PreviewPopUp = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  artifiIntegrationValues.appType = 'preview';
  const src = Artifi.getPreviewUrl(artifiIntegrationValues);

  if (Artifi.IsCompatibleBrowser()) {
    const artifiWindowSrc = window.open(src, '', `width=${artifiIntegrationValues.width},height=${artifiIntegrationValues.height}`);
    artifiWindowSrc.opener = null;
  } else {
    const artifiWindow = window.open('', '_blank', `width=${artifiIntegrationValues.width},height=${artifiIntegrationValues.height}`);
    artifiWindow.opener = null;
    jQuery(artifiWindow.document.body).append(Artifi.getElement());
  }
};

/**
 * Get Customized product list using a given integration value ; invokes callback, when that call is finished
 * @param {Object} integrationValues Interation values for getting Customized product data.
 * @param {Function} successCallback Callback to execute when finished loading data from server successfully
 * @param {Function} errorCallback Callback to execute when received error from server
 */
Artifi.GetCustomizedProductById = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  const src = `${domainIp}/Designer/Services/GetCustomizedProductById`;
  const headerKey = `?customizedProductId=${artifiIntegrationValues.customizedProductId}&websiteId=${artifiIntegrationValues.websiteId}&webApiClientKey=${artifiIntegrationValues.webApiclientKey}`;
  Artifi.ajaxHelper(src, 'GET', 'json', artifiIntegrationValues.successCallback, artifiIntegrationValues.errorCallback, headerKey);
};

/**
 * Get Customized product list of particular user Id  using a given integration value ; invokes callback, when that call is finished
 * @param {Object} integrationValues Interation values for getting Customized product list using userId.
 * @param {Function} successCallback Callback to execute when finished loading data from server successfully
 * @param {Function} errorCallback Callback to execute when received error from server
 */

Artifi.GetCustomizedProductByUserId = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  const src = `${domainIp}/Designer/Services/GetCustomizedProductByUserId`;
  const headerKey = `?userId=${artifiIntegrationValues.userId}&websiteId=${artifiIntegrationValues.websiteId}&webApiClientKey=${artifiIntegrationValues.webApiclientKey}&orderStatus=${artifiIntegrationValues.orderStatus}`;
  Artifi.ajaxHelper(src, 'GET', 'json', artifiIntegrationValues.successCallback, artifiIntegrationValues.errorCallback, headerKey);
};

/**
 * Get Customized Design Image list using a given integration value ; invokes callback, when that call is finished
 * @param {Object} integrationValues Interation values for getting Customized Design Image list.
 * @param {Function} successCallback Callback to execute when finished loading data from server successfully
 * @param {Function} errorCallback Callback to execute when received error from server
 */

Artifi.GetCustomizedDesignImageList = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  const src = `${domainIp}/Designer/Services/GetCustomizedDesignImageList`;
  const headerKey = `?customizedProductId=${artifiIntegrationValues.customizedProductId}&websiteId=${artifiIntegrationValues.websiteId}&webApiClientKey=${artifiIntegrationValues.webApiclientKey}`;
  Artifi.ajaxHelper(src, 'GET', 'json', artifiIntegrationValues.successCallback, artifiIntegrationValues.errorCallback, headerKey);
};

/**
 *  Get Customized Product from Artifi using a given integration value  ; invokes callback, when that call is finished
 * @param {Object} integrationValues Integration values for getting Customized Product.
 * @param {Function} successCallback Callback to execute when finished loading data from server successfully
 * @param {Function} errorCallback Callback to execute when received error from server
 */
Artifi.DeleteCustomizedProduct = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  const src = `${domainIp}/Designer/Services/DeleteCustomizedProduct`;
  const headerKey = `?customizedProductId=${artifiIntegrationValues.customizedProductId}&websiteId=${artifiIntegrationValues.websiteId}&webApiClientKey=${artifiIntegrationValues.webApiclientKey}`;
  Artifi.ajaxHelper(src, 'DELETE', 'json', artifiIntegrationValues.successCallback, artifiIntegrationValues.errorCallback, headerKey);
};

/**
 * Update Customize Product Status using a integration values. Order status should be in string format(InProgress,Placed) ; invokes callback, when that call is finished
 * @param {Object} integrationValues Integration values for update single Customize Product Status.
 * @param {Function} successCallback Callback to execute when finished loading data from server successfully
 * @param {Function} errorCallback Callback to execute when received error from server
 */
Artifi.UpdateCustomizedProductOrderStatus = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  const src = `${domainIp}/Designer/Services/UpdateCustomizedProductOrderStatus`;
  const headerKey = `?customizedProductId=${artifiIntegrationValues.customizedProductId}&websiteId=${artifiIntegrationValues.websiteId}&orderStatus=${artifiIntegrationValues.orderStatus}&webApiClientKey=${artifiIntegrationValues.webApiclientKey}`;
  Artifi.ajaxHelper(src, 'GET', 'json', artifiIntegrationValues.successCallback, artifiIntegrationValues.errorCallback, headerKey);
};

/**
 * Update Multiple Customize Product Status using a integration values. Order status should be in string format(InProgress,Placed) ; invokes callback, when that call is finished
 * @param {Object} integrationValues Integration values for update multiple Customize Product Status.
 * @param {Function} successCallback Callback to execute when finished loading data from server successfully
 * @param {Function} errorCallback Callback to execute when received error from server
 */
Artifi.UpdateMultipleCustomizedProductOrderStatus = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  const src = `${domainIp}/Designer/Services/UpdateMultipleCustomizedProductOrderStatus`;
  const headerKey = `?customizedProductIds=${artifiIntegrationValues.customizedProductIds}&websiteId=${artifiIntegrationValues.websiteId}&orderStatus=${artifiIntegrationValues.orderStatus}&webApiClientKey=${artifiIntegrationValues.webApiclientKey}`;
  Artifi.ajaxHelper(src, 'GET', 'json', artifiIntegrationValues.successCallback, artifiIntegrationValues.errorCallback, headerKey);
};

/**
 * Update User ID using a integration values.; invokes callback, when that call is finished
 * @param {Object} integrationValues Integration values for update User ID.
 * @param {Function} successCallback Callback to execute when finished loading data from server successfully
 * @param {Function} errorCallback Callback to execute when received error from server
 */
Artifi.UpdateUserId = function (integrationValues) {
  const artifiIntegrationValues = Artifi.getIntegrationValues(integrationValues);
  const src = `${domainIp}/Designer/Services/UpdateUserId`;
  const headerKey = `?newUserId=${artifiIntegrationValues.newUserId}&oldUserId=${artifiIntegrationValues.oldUserId}&IsGuest=${artifiIntegrationValues.isGuest}&websiteId=${artifiIntegrationValues.websiteId}&webApiClientKey=${artifiIntegrationValues.webApiclientKey}`;
  Artifi.ajaxHelper(src, 'GET', 'json', artifiIntegrationValues.successCallback, artifiIntegrationValues.errorCallback, headerKey);
};


/* Below function is exclusively made for scenarios where in client needs "Add to cart" button outside the Artifi iframe element.
 For such situation, below function needs to be invoked by CLIENT END.
 To initiate "Add to cart" process when the button is hit("Add to cart" button which is outside the Artifi iframe) */
Artifi.AddToCart = function () {
  const eventObject = {};
  eventObject.action = Artifi.Constant.addToCart;
  eventObject.data = '';
  const artifiIframe = jQuery(`#${Artifi.DivId}`).find('iframe')[0];
  if (typeof artifiIframe !== 'undefined' && artifiIframe != null) {
    artifiIframe.contentWindow.postMessage(eventObject, assetProtocol + domainIp);
  } else {
    console.log('Unable to post message because Artifi.DivId not found');
  }
};


/* Here we select artifi iframe for sending data via postmessage */
Artifi.ContinueSaveDesign = function () {
  const eventObject = {};
  eventObject.action = Artifi.Constant.continueSave;
  eventObject.data = '';
  const artifiIframe = document.getElementById(Artifi.DivId).getElementsByTagName('iframe')[0];
  if (typeof artifiIframe !== 'undefined' && artifiIframe != null) {
    artifiIframe.contentWindow.postMessage(eventObject, assetProtocol + domainIp);
  } else {
    console.log('Unable to post message because Artifi.DivId not found');
  }
};

/*
 The below function is invoked by client-end(parent window of Artifi Iframe) when the save draft button is hit at there end. */
Artifi.SaveDraft = function () {
  const eventObject = {};
  eventObject.action = Artifi.Constant.saveDraftExternally;
  eventObject.data = '';
  const artifiIframe = jQuery(`#${Artifi.DivId}`).find('iframe')[0];
  if (typeof artifiIframe !== 'undefined' && artifiIframe != null) {
    artifiIframe.contentWindow.postMessage(eventObject, assetProtocol + domainIp);
  } else {
    console.log('Unable to post message because Artifi.DivId not found');
  }
};


/* ===========================Private Method Start Here =======================  */

/* Method for validating domain */
Artifi.isValidArtifiDomain = function (url) {
  try {
    if (url) {
      url = Artifi.extractDomain(url);
      if (Artifi.extractDomain(domainIp).toLocaleLowerCase() == url.toLocaleLowerCase()) {
        return true;
      }
    }
  } catch (e) {
    return false;
  }
  return false;
};

Artifi.BuildDesignerUrl = function (artifiIntegrationValues) {
  const designerBaseUrl = `${domainIp}/Designer?`;
  let productCode = jQuery.trim(artifiIntegrationValues.productCode);
  productCode = Artifi.getEncodedProductCode(productCode);
  const { websiteId } = artifiIntegrationValues;
  let { userId } = artifiIntegrationValues;
  const { isGuest } = artifiIntegrationValues;
  const { extraQueryString } = artifiIntegrationValues;
  const supportDetails = Artifi.GetSupportDetails(artifiIntegrationValues.supportDetails);
  const { cartQueryString } = artifiIntegrationValues;
  const sku = jQuery.trim(artifiIntegrationValues.sku);
  if (!Artifi.isNumeric(userId) && userId.indexOf('#') != -1) {
    userId = encodeURIComponent(userId);
  }
  const { basePrice } = artifiIntegrationValues;
  let paramStr = `&websiteId=${websiteId}&userId=${userId}&isGuest=${isGuest}`;
  if (sku) {
    paramStr = `sku=${sku}${paramStr}`;
  } else {
    paramStr = `productCode=${productCode}${paramStr}`;
  }

  if (artifiIntegrationValues.webApiclientKey) {
    paramStr += `&webApiclientKey=${artifiIntegrationValues.webApiclientKey}`;
  }

  if (artifiIntegrationValues.designId) {
    paramStr += `&customizedProductId=${artifiIntegrationValues.designId}`;
  }
  if (artifiIntegrationValues.designGalleryCode) {
    paramStr += `&designGalleryCode=${artifiIntegrationValues.designGalleryCode}`;
  }

  if (artifiIntegrationValues.appMode) {
    paramStr += `&appMode=${artifiIntegrationValues.appMode}`;
  }

  if (artifiIntegrationValues.appType) {
    paramStr += `&appType=${artifiIntegrationValues.appType}`;
  }

  if (extraQueryString) {
    paramStr += `&extraQueryString=${encodeURIComponent(extraQueryString)}`;
  }
  if (supportDetails) {
    paramStr += `&supportDetails=${supportDetails}`;
  }

  if (cartQueryString) {
    paramStr += `&cartQueryString=${encodeURIComponent(cartQueryString)}`;
  }
  if (basePrice) {
    paramStr += `&basePrice=${encodeURIComponent(basePrice)}`;
  }
  return Artifi.GetEncodedLaunchUrl(designerBaseUrl, paramStr);
};

Artifi.getPreviewUrl = function (artifiIntegrationValues) {
  const previewBaseUrl = `${domainIp}/Preview?`;
  let productCode = jQuery.trim(artifiIntegrationValues.productCode);
  productCode = Artifi.getEncodedProductCode(productCode);
  const { websiteId } = artifiIntegrationValues;
  let { userId } = artifiIntegrationValues;
  const { isGuest } = artifiIntegrationValues;
  const { designId } = artifiIntegrationValues;
  const { webApiclientKey } = artifiIntegrationValues;
  const { appType } = artifiIntegrationValues;
  const { extraQueryString } = artifiIntegrationValues;
  const supportDetails = Artifi.GetSupportDetails(artifiIntegrationValues.supportDetails);
  const { cartQueryString } = artifiIntegrationValues;
  const sku = jQuery.trim(artifiIntegrationValues.sku);
  const { appMode } = artifiIntegrationValues;
  if (!Artifi.isNumeric(userId) && userId.indexOf('#') != -1) {
    userId = encodeURIComponent(userId);
  }
  let paramString = `&websiteId=${websiteId}&userId=${userId}&isGuest=${isGuest}&webApiclientKey=${webApiclientKey}`;
  if (sku) {
    paramString = `sku=${sku}${paramString}`;
  } else {
    paramString = `productCode=${productCode}${paramString}`;
  }
  if (designId) {
    paramString += `&customizedProductId=${designId}`;
  }
  if (supportDetails) {
    paramString += `&supportDetails=${supportDetails}`;
  }
  if (extraQueryString) {
    paramString += `&extraQueryString=${encodeURIComponent(extraQueryString)}`;
  }
  return Artifi.GetEncodedLaunchUrl(previewBaseUrl, paramString);
};

/* Method return the support details string in encoded format */
Artifi.GetSupportDetails = function (suportDetails) {
  if (suportDetails && typeof suportDetails === 'string') {
    return encodeURIComponent(decodeURIComponent(suportDetails));
  }
  if (suportDetails && typeof suportDetails === 'object') {
    return suportDetails ? encodeURIComponent(JSON.stringify(suportDetails)) : '';
  }
  return '';
};

Artifi.IsCompatibleBrowser = function () {
  if (!Artifi.IsCanvasSupport() || !Artifi.IsJsonSupport()) {
    console.log('canvas or json suport: False');
    return false;
  }
  return true;
};

Artifi.IsCanvasSupport = function () {
  const canvasElement = document.createElement('canvas');
  return !!(canvasElement.getContext && canvasElement.getContext('2d'));
};

Artifi.IsJsonSupport = function () {
  return window.JSON
        && window.JSON.parse
        && typeof window.JSON.parse === 'function'
        && window.JSON.stringify
        && typeof window.JSON.stringify === 'function';
};

Artifi.getElement = function () {
  jQuery('.FilterSection').css({ padding: '0px', margin: '0px' });

  return "<div class='contentBox UnSupportedBrowserDiv' style='border-bottom:1px solid #bababa; width:94%; margin:0,0,20px,2px'>"
        + "<div style='padding:10px 0 10px 0; background:#eee; font-size: 21px;font-weight: normal;line-height: 16px'>" + t('artifi.browser_is_not_supported') + "</div>"
        + "<div style='padding:10px 0 10px 40px;font-size: 15px;font-weight: normal;'>" + t('artifi.recomended_browsers') + "</div>"
        + "<ul style='padding:0,0,0,40px'>"
        + '<li>IE 10.x</li>'
        + '<li>Firefox ' + t('artifi.update_with_the_latest_version') + '</li>'
        + '<li>Chrome ' + t('artifi.update_with_the_latest_version') + '</li>'
        + '<li>Safari(Apple) 7.0 and above</li>'
        + '</ul>'
        + '</div>';
};

/* In this function we are converting all originalValues integration values into lowercase and then that lowercase values we are using to assign original array values
 If you want to add any new values for artifi initialization please insert new values in originalIntegrationKeys array;
 */
Artifi.getIntegrationValues = function (originalValues) {
  const originalIntegrationKeys = ['productCode', 'websiteId', 'userId', 'divId', 'webApiclientKey', 'designGalleryCode', 'isGuest', 'width', 'height', 'designId', 'sku', 'extraQueryString', 'supportDetails', 'cartQueryString', 'appType', 'customizedProductId', 'successCallback', 'errorCallback', 'customizedProductIds', 'newUserId', 'oldUserId', 'url', 'imageId', 'orderStatus', 'appMode', 'basePrice'];

  if (!jQuery.isEmptyObject(originalValues)) {
    try {
      let key; const
        keys = Object.keys(originalValues);
      let dataLength = keys.length;
      const newIntegrationKey = {};
      while (dataLength--) {
        key = keys[dataLength];
        newIntegrationKey[key.toLowerCase()] = originalValues[key];
      }
      const artificationData = {};
      jQuery.each(originalIntegrationKeys, (orgKeys, orgVals) => {
        if (newIntegrationKey[orgVals.toLowerCase()] != 'undefined' && newIntegrationKey[orgVals.toLowerCase()] != null) artificationData[orgVals] = newIntegrationKey[orgVals.toLowerCase()];
        else artificationData[orgVals] = '';
      });
      return artificationData;
    } catch (e) {
      console.log(`ArtifiIntegration.js : MethodName:-getIntegrationValues()  ${e.stack}`);
    }
  } else {
    console.log('ArtifiIntegration.js : MethodName:-getIntegrationValues()  Artifi Integration Parameters are missing  ');
  }
};

Artifi.extractDomain = function (url) {
  let domain;
  if (url.indexOf('://') > -1) {
    domain = url.split('/')[2];
  } else if (url.indexOf('//') > -1) {
    domain = url.split('/')[2];
  } else {
    domain = url.split('/')[0];
  }
  domain = domain.split(':')[0];
  return domain;
};

Artifi.loadUrlInIframe = function (src, artifiIntegrationValues) {
  try {
    if (Artifi.IsCompatibleBrowser()) {
      const iframe = document.createElement('iframe');
      iframe.src = src;
      iframe.height = artifiIntegrationValues.height;
      iframe.width = artifiIntegrationValues.width;
      iframe.frameBorder = 0;
      iframe.style.position = 'relative';
      jQuery(`#${artifiIntegrationValues.divId}`).append(iframe);
      return true;
    }

    jQuery(`#${artifiIntegrationValues.divId}`).append(Artifi.getElement());
  } catch (ex) {
    console.log(`Error in Artifi.Initialize${ex.stack}`);
  }
  return false;
};

Artifi.ajaxHelper = function (src, type, dataType, successCallback, errorcallback, headerKey) {
  jQuery.support.cors = true;
  jQuery.ajax({
    url: src,
    beforeSend(xhr) {
      if (headerKey) {
        xhr.setRequestHeader(Artifi.personalizationKey, Base64.encode(headerKey));
      }
    },
    type,
    dataType,
    success(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
    error(x, y, z) {
      if (errorcallback) {
        errorcallback(`${x}\n${y}\n${z}`);
      }
    },
  });
};


/* Here we  are check the decoded base64 string if staring length has % 4 == 2  then we add the '@' char in productCode or Sku value and its remove from server ends .
 * TODO: This is not permeant fix we need to check again
 */
Artifi.GetEncodedLaunchUrl = function (defaultFrontURL, launchParameter) {
  // replacing  char '+' to '_' and '/' to '-' and '=' to ''
  let launchDesignerUrl = '#';
  let base64LaunchUrl = '';
  try {
    if (Artifi.IsUrlEncodingEnableInLaunching) {
      if (launchParameter.length % 3 == 2) {
        base64LaunchUrl = Base64.encode(launchParameter);
        base64LaunchUrl = base64LaunchUrl.replace(/\r?\n/g, '');
        base64LaunchUrl = base64LaunchUrl.replace(/\+/g, '_').replace(/\//g, '-').replace(/=/g, '');
      } else if (launchParameter.length % 3 == 1) {
        var finalParameter = Artifi.modifyProductCodeAndSKU(launchParameter, 1);
        base64LaunchUrl = Artifi.getModifiedLaunchUrl(finalParameter);
      } else if (launchParameter.length % 3 == 0) {
        var finalParameter = Artifi.modifyProductCodeAndSKU(launchParameter, 2);
        base64LaunchUrl = Artifi.getModifiedLaunchUrl(finalParameter);
      }
      launchDesignerUrl = `${defaultFrontURL + Artifi.personalizationKey}=${base64LaunchUrl}`;
    } else {
      launchDesignerUrl = defaultFrontURL + launchParameter;
    }
    return launchDesignerUrl;
  } catch (e) {
    console.log('Launching Url Error in GetEncodedLaunchURL()', e.stack);
  }
};


/* Function for adding '@' to product code or sku */
Artifi.getModifiedLaunchUrl = function (launchParameter) {
  const launchDesignerUrl = '#';
  let base64UrlWithModifiedCode = Base64.encode(launchParameter);
  base64UrlWithModifiedCode = base64UrlWithModifiedCode.replace(/\r?\n/g, '');
  base64UrlWithModifiedCode = base64UrlWithModifiedCode.replace(/\+/g, '_').replace(/\//g, '-').replace(/=/g, '');
  return base64UrlWithModifiedCode;
};

/* Function for adding '@' to product code or sku */
Artifi.modifyProductCodeAndSKU = function (launchParameter, charectorAddCount) {
  let charectorstring = '';
  for (let count = 0; count < charectorAddCount; count++) {
    charectorstring = `${charectorstring}@`;
  }
  const parameterlist = launchParameter.split('&');
  for (let i = 0; i < parameterlist.length; i++) {
    if (parameterlist) {
      const paramString = parameterlist[i].split('=');
      let key = paramString[0];
      let val = paramString[1];
      if (key && (key.toLowerCase() == 'productcode' || key.toLowerCase() == 'sku')) {
        val += charectorstring;
        key = `${key}=${val}`;
        parameterlist[i] = key;
      }
    }
  }
  return parameterlist.join('&');
};

Artifi.getEncodedProductCode = function (productCode) {
  productCode = decodeURIComponent(productCode);
  return encodeURIComponent(productCode);
};

/* This function is used to set the sku data from client to Artifi
* @param [skuData]{object} : skuData contains sku,quantity
* SKU : SKU of the product
* quantity : total quantity for that SKU
*/
Artifi.setSkuDetails = function (skuData) {
  const eventObject = {};
  eventObject.action = Artifi.Constant.externalVariantData;
  eventObject.data = skuData;
  const artifiIframe = document.getElementById(Artifi.DivId).getElementsByTagName('iframe')[0];
  if (typeof artifiIframe !== 'undefined' && artifiIframe != null) {
    artifiIframe.contentWindow.postMessage(eventObject, assetProtocol + domainIp);
  } else {
    console.log('Unable to post message because Artifi.DivId not found');
  }
};

/* This Method is used to clear all sku selection in artifi */
Artifi.clearAllSKU = function () {
  const eventObject = {};
  eventObject.action = Artifi.Constant.clearAllSku;
  eventObject.data = '';
  const artifiIframe = document.getElementById(Artifi.DivId).getElementsByTagName('iframe')[0];
  if (typeof artifiIframe !== 'undefined' && artifiIframe != null) {
    artifiIframe.contentWindow.postMessage(eventObject, assetProtocol + domainIp);
  } else {
    console.log('Unable to post message because Artifi.DivId not found');
  }
};

/* This function is for removing jquery dependency */
Artifi.isNumeric = function (obj) {
  const rdigit = /\d/;
  return obj != null && rdigit.test(obj) && !isNaN(obj);
};

export default Artifi;
