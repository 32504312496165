import React from 'react';
import styled from 'styled-components';
import {
  Title, Description, Color, Text,
} from 'mac-uikit';
import { useTranslation as t} from 'react-i18next';

const TermsContainer = styled.div`
  padding: 25px;
  background-color: white;
`;

const StyledText = styled(Text)`
  margin-top: 15px;
`


const Terms = () => (
  <TermsContainer>
    <StyledText>
      {t('Terms.01')}
    </StyledText>
    <StyledText>
    {t('terms.02_first')} <a href="https://www.maccosmetics.com/terms-conditions">https://www.maccosmetics.com/terms-conditions </a> {t('terms.02_second')} <a href="https://www.maccosmetics.com/privacy-policy">https://www.maccosmetics.com/privacy-policy</a>, {t('terms.02_third')}
    </StyledText>
    <StyledText>
    {t('Terms.03')}
    </StyledText>
    <StyledText>
    {t('Terms.04')}
    </StyledText>

    <StyledText>
    {t('Terms.05')}

    </StyledText>
    <StyledText>
    {t('Terms.06')}

    </StyledText>
    <StyledText>
    {t('Terms.07')}

    </StyledText>
    <StyledText>
    {t('Terms.08')}

    </StyledText>
    <StyledText>
    {t('Terms.09')}

    </StyledText>
    <StyledText>
    {t('Terms.10')}

    </StyledText>
    <StyledText>
    {t('terms.last_updated')}
    </StyledText>
  </TermsContainer>
);

export default Terms;
