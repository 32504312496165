import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import './i18n';

import configureStore from './configureStore';

const tagManagerArgs = {
  gtmId: 'GTM-5DNKQM5',
  dataLayer: {
    userId: '001',
    userProject: 'mac-red',
  },
};

TagManager.initialize(tagManagerArgs);

const { store, persistor } = configureStore();

ReactDOM.render(<Provider store={store}><PersistGate loading={null} persistor={persistor}><Suspense fallback=""><App /></Suspense></PersistGate></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
