import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    backend: {
      /* translation file path */
      loadPath: '/translations/{{lng}}.json',
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
    },
  });

export default i18n;
