import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { confirmOrder, resetOrder } from 'src/reducers/order';
import { Title, Button } from 'mac-uikit';
import { useTranslation } from 'react-i18next';

const Img = styled.img`
  max-height: 30vh;
  height: 100%;
  width: auto;
  margin: 50px auto;
`;
const ConfirmationContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 90vw;
`;

const CustomizationDescription = styled.p`
  color: white;
  font-size: 12px;
  height: auto;
  word-break: break-all;
`;

const Preview = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { order } = useSelector((store) => store);
  const componentRef = useRef();
  const { t } = useTranslation();

  const confirm = () => {
    dispatch(confirmOrder(order.id));
    history.push('confirm');
  };

  const newOrder = () => {
    dispatch(resetOrder());
    history.push('/');
  };

  return (
    <ConfirmationContainer ref={componentRef}>
      <Title fontSize={4} hasPaddingTop>{t('preview.preview_screen')}</Title>
      <Img src={order.response.savedDesigns[0]} />
      <Title fontSize={1}>{t('preview.description_of_the_customization')}</Title>
      <CustomizationDescription>
        {JSON.stringify(order.response.customizedData)}
      </CustomizationDescription>
      <Button onClick={() => newOrder()}>{t('preview.reset_experience')}</Button>
      <Button onClick={() => confirm()}>{t('preview.confirm')}</Button>
    </ConfirmationContainer>
  );
};

export default Preview;
