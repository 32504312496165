import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Color, Text } from 'mac-uikit';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

import Home from 'src/pages/Home/Home';
import LipstickHome from 'src/pages/Home/Lipstick';
import Palette4Home from 'src/pages/Home/Palette4';
import Palette9Home from 'src/pages/Home/Palette9';

import ApplicationInsightsManager from 'src/Analytics/ApplicationInsightManager';

import ExperienceSelector from 'src/pages/ExperienceSelector/ExperienceSelector';
import Palette from 'src/pages/Palette/Palette';
import BigPalette from 'src/pages/BigPalette/BigPalette';
import Lipstick from 'src/pages/Lipstick/Lipstick';
import Confirmation from 'src/pages/Confirmation/Confirmation';
import Preview from 'src/pages/Preview/Preview';
import Pong from 'src/pages/Pong/Pong';
import ConfirmationEmail from 'src/pages/ConfirmationEmail/ConfirmationEmail';
import Terms from 'src/pages/Terms/Terms';

import './App.scss';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';



const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: ${Color.black};
`;

const ShadowContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: #000000cf;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;


export default function BasicExample() {
  const { t } = useTranslation();

  const disableScroll = (orientation) => {
    document.body.style.overflow = orientation === 'portrait' ? 'auto' : 'hidden';
  };

  function setupAnalytics() {
    // console.log("SETUP ANALYTICS");
    ApplicationInsightsManager.shared.load();
  }

  useEffect(() => {
    // Setup Analytics
    setupAnalytics()
  }, [])

  return (
    <Router>
      <DeviceOrientation lockOrientation="landscape" className="deviceOrientation" onOrientationChange={disableScroll}>
        {/* Will only be in DOM in landscape */}
        <Orientation orientation="landscape" alwaysRender={false}>
          <ShadowContainer>
            <Text color={Color.white} size={3}>{t('app.uh_oh')}</Text>
            <Text color={Color.white} size={1.75} marginTop={15}>
              {t('app.customize_your_product')}
            </Text>
            <Text color={Color.white} size={1.75}>{t('app.rotate_your_phone')}</Text>
          </ShadowContainer>
        </Orientation>
        {/* Will stay in DOM, but is only visible in portrait */}
        <Orientation orientation="portrait">
          <Container>
            <Switch>
              <Route path="/ping">
                <Pong />
              </Route>
              <Route path="/lipstick">
                <Lipstick />
              </Route>
              <Route path="/palette">
                <Palette />
              </Route>
              <Route path="/palette9">
                <BigPalette />
              </Route>
              <Route path="/preview">
                <Preview />
              </Route>
              <Route path="/confirm">
                <Confirmation />
              </Route>
              <Route path="/email">
                <ConfirmationEmail />
              </Route>
              <Route path="/start/lipstick">
                <LipstickHome type="lipstick" />
              </Route>
              <Route path="/start/palette">
                <Palette4Home type="Palette-X4" />
              </Route>
              <Route path="/start/palette9">
                <Palette9Home type="Palette-X9" />
              </Route>
              <Route path="/terms-conditions">
                <Terms />
              </Route>
              <Route exact path="/">
                <ExperienceSelector />
              </Route>
            </Switch>
          </Container>
        </Orientation>
      </DeviceOrientation>

    </Router>
  );
}
