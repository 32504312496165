import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { P, Color } from 'mac-uikit';
import LipstickImage from 'src/assets/images/lipstick.png';
import Palette4 from 'src/assets/images/palette4.png';
import Palette9 from 'src/assets/images/palette9.jpg';
import { useTranslation } from 'react-i18next';

const ExperienceSelectorContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin-top: 25px;
`;
const ExperienceContainer = styled.div.attrs({
  className: 'ExperienceContainer',
})`
    text-align: center;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
`;

const ExperienceTitle = styled(P)`
    color: ${Color.black};
    padding: 0 40px;
    justify-self: baseline;
`;

const Experience = ({ title, image, type }) => {
  const history = useHistory();

  return (
    <ExperienceContainer onClick={() => history.push(type)}>
      <img src={image} alt="" />
      <ExperienceTitle>{title}</ExperienceTitle>
    </ExperienceContainer>
  );
};

const ExperienceSelector = () => {
  const { t } = useTranslation();

  return (
    <ExperienceSelectorContainer>
      <Experience image={LipstickImage} title={t('experience_selector.lipstick')} type="lipstick" />
      <Experience image={Palette4} title={t('experience_selector.eyeshadow_palette_4')} type="palette" />
      <Experience image={Palette9} title={t('experience_selector.eyeshadow_palette_9')} type="palette9" />
    </ExperienceSelectorContainer>
  );
};

Experience.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ExperienceSelector;
